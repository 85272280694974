import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
// import Slide from "react-reveal/Reveal"

import styles from "./styles.module.less"

import hero from "./img/strategy-hero.jpg"

// export default class Strategy extends Component {
//   render() {
const Strategy = () => {
  return (
    <section className={styles.Strategy}>
      <Reveal>
        <div className={styles.StrategyHero}>
          <img src={hero} />
        </div>
        <div className={styles.title}>
          <div className={styles.title1}>
            Strong results start with a strong
          </div>
          <div className={styles.title2}>STRATEGY</div>
          <div className={styles.title3}>
            Shift away from content generation. Approach modern editorial.
            <br />
            <br />
            Wield data—AKA researched keyword opportunities—in concert with rich
            editorial to orchestrate a seismic SEO effort that pulls people
            closer to the Adobe brand.
          </div>
        </div>
        <div className={styles.summary}>
          <div className={styles.header}>
            <h2>
              <strong>
                Hypothesis:
                <br />
                You’ll see all the right lift in all the right places.
              </strong>
            </h2>
          </div>
        </div>
      </Reveal>
    </section>
  )
}

export default Strategy
