import React, { Component } from "react"

//styles
import styles from "./styles.module.less"

//img
import adobeDesk from "./img/adobedesk2x.jpg"
import adobeLogo from "./img/adobelogo.png"

export default class Hero extends Component {
  render() {
    return (
      <div className={styles.CodeHero}>
        <div className={styles.relative}>
          <div className={styles.hero}>
            <div className={styles.textsm}>CASE STUDY: ADOBE.COM</div>
            <div className={styles.textlg}>
              SEO articles can—
              <br />
              and should—
              <br />
              put the <em>fun</em> back
              <br /> in <i>function.</i>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.center}>
            <div className={styles.text}>
              <h2 className={styles.title}>
                Making Adobe findable <br className={styles.display} />
                via non-Adobe keywords.
              </h2>
            </div>
          </div>
          <div className={styles.seeing}>
            <div className={styles.logo}>
              <img src={adobeLogo} alt="" />
            </div>
            <div className={styles.img}>
              <img src={adobeDesk} alt="" />
            </div>
          </div>
          <div className={styles.text}>
            <p className={styles.par}>
              Your brand is the industry standard for creative software. Fans
              know your name and where to find you—but what about the rest of
              the internet? You need to attach yourself to non-branded keywords
              to attract audiences who don’t know your greatness … yet.
            </p>
          </div>
          <div className={styles.borderBlock}>
            <h3>How to get after it:</h3>
            <h2>
              Increase search traffic to Adobe.com by bucking the trend that SEO
              pages are nuthin’ but noise.
            </h2>
            <h2>How exactly?</h2>
            <ul>
              <li>
                Create helpful, entertaining, professionally informed articles
                for beginners.
              </li>
              <li>Give them the answers they’re searching for.</li>
              <li>Use stories to make the complex more conversational. </li>
              <li>Build a relationship that helps them reach their goals.</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
