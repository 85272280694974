import PCR_Scroll_1 from "./PCR_Scroll_1.jpg"
import PCR_Scroll_2 from "./PCR_Scroll_2.jpg"
import PCR_Scroll_3 from "./PCR_Scroll_3.jpg"
import PCR_Scroll_4 from "./PCR_Scroll_4.jpg"
import PCR_Scroll_5 from "./PCR_Scroll_5.jpg"
import PCR_Scroll_6 from "./PCR_Scroll_6.jpg"
import PCR_Scroll_7 from "./PCR_Scroll_7.jpg"
import PCR_Scroll_8 from "./PCR_Scroll_8.jpg"
import PCR_Scroll_9 from "./PCR_Scroll_9.jpg"
import PCR_Scroll_10 from "./PCR_Scroll_10.jpg"
import PCR_Scroll_11 from "./PCR_Scroll_11.jpg"
import PCR_Scroll_12 from "./PCR_Scroll_12.jpg"
import PCR_Scroll_13 from "./PCR_Scroll_13.jpg"
import PCR_Scroll_14 from "./PCR_Scroll_14.jpg"
import PCR_Scroll_15 from "./PCR_Scroll_15.jpg"
import PCR_Scroll_16 from "./PCR_Scroll_16.jpg"
import PCR_Scroll_17 from "./PCR_Scroll_17.jpg"
import PCR_Scroll_18 from "./PCR_Scroll_18.jpg"
import PCR_Scroll_19 from "./PCR_Scroll_19.jpg"
import PCR_Scroll_20 from "./PCR_Scroll_20.jpg"
import PCR_Scroll_21 from "./PCR_Scroll_21.jpg"
import PCR_Scroll_22 from "./PCR_Scroll_22.jpg"
import PCR_Scroll_23 from "./PCR_Scroll_23.jpg"
import PCR_Scroll_24 from "./PCR_Scroll_24.jpg"
import PCR_Scroll_25 from "./PCR_Scroll_25.jpg"
import PCR_Scroll_26 from "./PCR_Scroll_26.jpg"
import PCR_Scroll_27 from "./PCR_Scroll_27.jpg"
import PCR_Scroll_28 from "./PCR_Scroll_28.jpg"
import PCR_Scroll_29 from "./PCR_Scroll_29.jpg"
import PCR_Scroll_30 from "./PCR_Scroll_30.jpg"
import PCR_Scroll_31 from "./PCR_Scroll_31.jpg"
import PCR_Scroll_32 from "./PCR_Scroll_32.jpg"
import PCR_Scroll_33 from "./PCR_Scroll_33.jpg"
import PCR_Scroll_34 from "./PCR_Scroll_34.jpg"
import PCR_Scroll_35 from "./PCR_Scroll_35.jpg"
import PCR_Scroll_36 from "./PCR_Scroll_36.jpg"

export default [
  PCR_Scroll_1,
  PCR_Scroll_2,
  PCR_Scroll_3,
  PCR_Scroll_4,
  PCR_Scroll_5,
  PCR_Scroll_6,
  PCR_Scroll_7,
  PCR_Scroll_8,
  PCR_Scroll_9,
  PCR_Scroll_10,
  PCR_Scroll_11,
  PCR_Scroll_12,
  PCR_Scroll_13,
  PCR_Scroll_14,
  PCR_Scroll_15,
  PCR_Scroll_16,
  PCR_Scroll_17,
  PCR_Scroll_18,
  PCR_Scroll_19,
  PCR_Scroll_20,
  PCR_Scroll_21,
  PCR_Scroll_22,
  PCR_Scroll_23,
  PCR_Scroll_24,
  PCR_Scroll_25,
  PCR_Scroll_26,
  PCR_Scroll_27,
  PCR_Scroll_28,
  PCR_Scroll_29,
  PCR_Scroll_30,
  PCR_Scroll_31,
  PCR_Scroll_32,
  PCR_Scroll_33,
  PCR_Scroll_34,
  PCR_Scroll_35,
  PCR_Scroll_36,
]
