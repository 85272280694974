import React, { Component } from "react"

import styles from "./styles.module.less"

export default class Credits extends Component {
  render() {
    return (
      <section className={styles.Out}>
        <div className={styles.conclusion}>
          <div className={styles.h6}>Credits</div>
          <div className={styles.text3}>
            Thanks to the Adobe SEO team. AKA the real people who made the real
            work.
            <br />
            <b>Account Managers:</b> Liz Goodin, Claire Sonnier <br />
            <b>Project Managers:</b> Megan Trevarthen, Regina Atoigue <br />
            <b>Strategist:</b> Nathan Stenberg
            <br />
            <b>Design:</b> Allison Bowe, Wolfgang Vosburgh, Lenore Prato, Ryan
            Smith, Rachelle Haines, Joe Carolino, Becca Pierpont
            <br />
            <b>Writing:</b> Jim Gibbons, Elizabeth Harney, Jeremy Resnick, Mary
            Yajko, Margaret Park Bridges, Elise Choi, Joe Streckert, Thomas
            Wells, Annie Russell, Christine Miller, Hannah Dugan
          </div>
        </div>
      </section>
    )
  }
}
