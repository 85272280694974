import React from "react"

import AdobeSeo from "components/CaseStudies/AdobeSeo"
import Layout from "components/Layout"
import SEO from "components/seo"

const AdobeSeoCaseStudy = () => (
  <Layout backgroundColor={"white"} bannerBgColor="" isArchive>
    <SEO
      title="Thesis | Case study: Adobe SEO"
      description="We helped Adobe create over 100 informative, entertaining, and SEO-rich editorial articles about topics like photography and design to bring new creatives into the Adobe network. Read the case study."
    />
    <AdobeSeo />
  </Layout>
)

export default AdobeSeoCaseStudy
