import React, { Component, useEffect, useState, useRef } from "react"
import Draggable from "react-draggable"
import Blazy from "blazy"

import styles from "./styles.module.less"
import "./styles.less"

import mobileHeroAsset from "./img/creative-hero.jpg"
import guy from "./img/guy.svg"

import screens from "./screens"

const pix =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="

export default class Creative extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }
  componentDidMount() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.setState({
            isVisible: true,
          })
        }
      })
    }, {})
    this.observer.observe(this.arrowWrapper)
  }

  componentWillUnmount() {
    this.observer && this.observer.disconnect()
  }

  render() {
    return (
      <div className={styles.Creative}>
        <div>
          <img src={mobileHeroAsset} className={styles.heroImg} />
        </div>

        <div className={styles.heroText}>
          <div className={styles.preTitle}>
            Best-laid plans thrive with best-best
          </div>
          <h1 className={styles.heroTitle}>CREATIVE</h1>
          <p className={styles.heroPar}>
            Working in month-long sprints, the Writing and Design teams dug
            deep. Tens of thousands of words were written; hundreds of images
            were curated to construct the final articles.
          </p>
        </div>

        <div className={styles.p2Gif}>
          <img src="/images/Creative.gif" alt="" />
        </div>

        <div className={styles.p3}>
          <div className={styles.p3text}>
            <h1 className={styles.p3title}>108</h1>
            <p className={styles.p3subtitle}>new pages published</p>
          </div>
        </div>

        <div className={styles.p4}>
          <h1 className={styles.p4title}>
            More than just pretty words and pictures,
          </h1>
          <p className={styles.p4par}>
            each page was primed for SEO publishing—optimized copy, custom
            metadata, and intentional HTML structures that maximized SEO
            scoring.
          </p>
        </div>

        <ScreenGridScroll />
        <ScreenGridDrag />

        <div className={styles.wrap}>
          <div
            className={styles.arrow1}
            ref={node => (this.arrowWrapper = node)}
            // style={{ maxHeight: this.state.height + 'px' }}
          >
            <div className={styles.mobile}>
              <svg
                width="183px"
                height="843px"
                viewBox="0 0 183 843"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Page"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <g
                    id="Adobe-PCR-Mobile"
                    transform="translate(-134.000000, -8614.000000)"
                    stroke="#FFFFFF"
                    strokeWidth="3"
                  >
                    <g
                      id="P6-Creative"
                      transform="translate(-78.000000, 6324.000000)"
                    >
                      <g
                        id="P6-img-3-Text_animation"
                        transform="translate(0.000000, 2146.000000)"
                      >
                        <g id="P6-Overlay-Element-2">
                          <g
                            id="Arrow"
                            transform="translate(210.000000, 146.000000)"
                          >
                            <path
                              className={`${
                                this.state.isVisible ? styles.strokeMob : ""
                              }`}
                              d="M54.1900368,-4.54747351e-13 C75.2727757,115.360444 188.818203,154.515625 182.818203,255.882812 C179.589793,310.425285 138.796502,333.050781 114.769766,462.175781 C99.4203153,544.667126 158.253757,646.504703 142.867417,727.203921 C130.997353,802.149196 70.96875,853.613244 5,826.476525"
                              id="Path-14"
                            ></path>
                            <polyline
                              className={`${
                                this.state.isVisible ? styles.strokeArrow : ""
                              }`}
                              id="Path-15"
                              transform="translate(9.366777, 829.900000) rotate(-345.000000) translate(-9.366777, -829.900000) "
                              points="11.7358231 838.8 2.46120898 826.820937 16.2723445 821"
                            ></polyline>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>{" "}
            </div>
            <div className={styles.tablet}>
              <svg
                width="332px"
                height="838px"
                viewBox="0 0 332 838"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Page"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <g
                    id="Adobe-PCR-Tablet"
                    transform="translate(-70.000000, -8883.000000)"
                    stroke="#FFFFFF"
                    strokeWidth="3"
                  >
                    <g
                      id="P6-Creative"
                      transform="translate(-1.000000, 6593.000000)"
                    >
                      <g
                        id="P6-img-3-Text_animation"
                        transform="translate(71.000000, 2211.000000)"
                      >
                        <g
                          id="P6-Overlay-Element-2"
                          transform="translate(0.500000, 0.000000)"
                        >
                          <g
                            id="Arrow"
                            transform="translate(165.000000, 498.000000) scale(-1, 1) translate(-165.000000, -498.000000) translate(0.500000, 80.000000)"
                          >
                            <path
                              className={`${
                                this.state.isVisible ? styles.stroke : ""
                              }`}
                              d="M0,1 C185.811136,-0.0223229656 325.436954,44.7219986 328,212.996253 C330,344.304279 206.026736,325.476563 182,454.601563 C157.973264,583.726563 259.058215,650.316406 243.671875,731.015625 C231.801811,805.9609 154.957031,848.136719 88.9882812,821"
                              id="Path-14"
                            ></path>
                            <polyline
                              className={`${
                                this.state.isVisible ? styles.strokeArrow : ""
                              }`}
                              id="Path-15"
                              points="94.222441 835.285049 88 820.067075 101.811136 815"
                            ></polyline>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div className={styles.desc}>
              <svg
                width="659px"
                height="954px"
                viewBox="0 0 659 954"
                version="1.1"
              >
                <g
                  id="Page"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                >
                  <g
                    id="Adobe-PCR-Desktop_R2"
                    transform="translate(-154.000000, -10888.000000)"
                    stroke="#FFFFFF"
                    strokeWidth="3"
                  >
                    <g
                      id="Creative"
                      transform="translate(-361.000000, 7442.000000)"
                    >
                      <g
                        id="Group-23"
                        transform="translate(429.000000, 3351.637933)"
                      >
                        <g
                          id="Group-14"
                          transform="translate(87.000000, 95.162162)"
                        >
                          <g id="arrow">
                            <path
                              className={`${
                                this.state.isVisible ? styles.stroke : ""
                              }`}
                              d="M366,0.812573497 C257.35973,12.6619512 25.7246913,22.5939323 2.9224798,175.471196 C-19.8797317,328.34846 133.517901,392.459912 121.258951,507.164265 C109,621.868618 7.31992394,670.291232 7.31992394,790.276343 C7.31992394,910.261453 94.2240028,951.305425 179.661858,951.305425 C312.509405,951.215387 353.461082,780.363121 484.230541,780.363121 C571.41018,780.363121 627.820706,817.891104 653.462117,892.94707"
                              id="Path-8"
                            ></path>
                            <polyline
                              className={`${
                                this.state.isVisible ? styles.strokeArrow : ""
                              }`}
                              id="Path-9"
                              strokeLinejoin="round"
                              points="633.495563 876.763288 653.462117 892.94707 656.053457 870.118865"
                            ></polyline>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <div className={styles.p5Text}>
            <h1 className={styles.p5title}>
              101<small>,</small>639
            </h1>
            <p className={styles.p5subtitle}>words written*</p>
          </div>
          <LoremFlow />
          <div className={styles.sub}>
            <div className={styles.p5subText}>
              *That’s longer than <em>To Kill a Mockingbird</em>
            </div>
            <div className={`${this.state.isVisible ? styles.slide : ""}`}>
              <img src={guy} alt="" />
            </div>
          </div>
        </div>
        <div></div>
        <div className={styles.whiteBlock}>
          <h1 className={styles.whiteBlockTitle}>
            Not your average snooze-inducing SEO content.
          </h1>
          <p className={styles.whiteBlockPar}>
            These were well-considered editorial pages with the user’s
            experience top of mind–always relevant, always entertaining.
          </p>
        </div>
      </div>
    )
  }
}

const LoremFlow = () => {
  const [words, setWords] = useState("")
  const [fetched, setFetched] = useState(false)
  const container = useRef(null)

  useEffect(() => {
    if (!fetched) {
      setFetched(true)
      fetch("/lorem.txt")
        .then(response => response.text())
        .then(text => {
          let lorem = text.split("\n\n")
          let observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
              let t = setInterval(() => {
                if (lorem.length === 0) {
                  clearInterval(t)
                  observer.disconnect()
                  return
                }
                setWords(previous => [previous, lorem.shift()].join(" "))
              }, 200)
            }
          }, {})
          observer.observe(container.current)
        })
        .catch(err => console.log(err))
    }
  })
  return (
    <div ref={container} className={styles.LoremFlow}>
      <p>
        <strong>For context, this is what 101,639 words looks like: </strong>
      </p>
      <p>{words}</p>
    </div>
  )
}

/**
 * Scrollable image grid for mobile
 */
const ScreenGridScroll = () => {
  const [isTouch, setTouch] = useState(true)
  useEffect(() => {
    setTouch(
      navigator.maxTouchPoints || "ontouchstart" in document.documentElement
    )
    window.blazy = new Blazy({
      container: "#pcr-creative-screen-scroll-grid",
      loadInvisible: true,
    })
  })

  const handleScroll = () => {
    return window.blazy && window.blazy.revalidate()
  }

  // if desktop device, will fallback to draggable
  if (!isTouch) return null

  return (
    <div
      id="pcr-creative-screen-scroll-grid"
      className={styles.ScreenGridScroll}
      onScroll={handleScroll}
    >
      <div className={styles.ScreenGrid}>
        {screens.map((i, j) => {
          return (
            <img key={j} className="b-lazy" src={pix} data-src={i} alt="" />
          )
        })}
      </div>
    </div>
  )
}

/**
 * Draggable image grid for desktop
 */
const defaultDragDepth = 1300
const ScreenGridDrag = () => {
  const [l, setLeft] = useState(defaultDragDepth)
  const [r, setRight] = useState(-defaultDragDepth)
  const [isTouch, setTouch] = useState(true)
  const ref = useRef()
  useEffect(() => {
    setTouch(
      navigator.maxTouchPoints || "ontouchstart" in document.documentElement
    )
    window.blazy = new Blazy({
      container: "#pcr-creative-screen-touch-grid",
      loadInvisible: true,
    })
    if (ref.current) {
      let { width } = ref.current.getBoundingClientRect()
      let padding = parseInt(
        window.getComputedStyle(ref.current).getPropertyValue("padding-left")
      )
      setLeft(width - window.innerWidth) // maximum left drag
      setRight(window.innerWidth - 2 * padding) // maximum right drag
    }
  })

  const handleDrag = () => {
    window.blazy && window.blazy.revalidate()
  }

  // hide by default, enable on touchless desktop
  if (isTouch) return null

  return (
    <Draggable
      axis="x"
      onDrag={handleDrag}
      bounds={{ top: 0, bottom: 0, right: r, left: -l }}
    >
      <div
        ref={ref}
        id="pcr-creative-screen-touch-grid"
        className={styles.ScreenGridDrag}
      >
        {screens.map((i, j) => {
          return (
            <img key={j} className="b-lazy" src={pix} data-src={i} alt="" />
          )
        })}
      </div>
    </Draggable>
  )
}
