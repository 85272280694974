import React from "react"

import Hero from "./components/Hero"
import Creative from "./components/Creative"
import Agrade from "./components/Agrade"
import Operations from "./components/OperationsSection"
import Conclusion from "./components/Conclusion"
import Credits from "./components/Credits"
import Bottom from "./components/Bottom"
import Strategy from "./components/Strategy"

const AdobeSeo = () => (
  <div>
    <Hero />
    <Strategy />
    <Operations />
    <Creative />
    <Agrade />
    <Conclusion />
    <Credits />
    <Bottom />
  </div>
)

export default AdobeSeo
