import React, { Component } from "react"

import styles from "./styles.module.less"

export default class Conclusion extends Component {
  render() {
    return (
      <div className={styles.Conclusion}>
        <div className={styles.borderBlock}>
          <h3>How it worked out:</h3>
          <h2>
            We partnered to create a repository of functional editorial articles
            that increased organic traffic to Creative Cloud SEO content pages
            by 88% in the first six months.
            <br />
            <br />
            Bonus: Adobe also experienced a 139% increase in revenue driven by
            these focused, accessible articles through organic search—proof that
            this Herculean editorial effort made our partner’s products more
            accessible than ever before.
            <br />
            <br />
            And the day is still young.
          </h2>
        </div>{" "}
      </div>
    )
  }
}
