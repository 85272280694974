import React, { Component } from "react"

import styles from "./styles.module.less"

import asset1 from "./img/operations-p5-img.jpg"
import asset2 from "./img/operations-p5-img2.jpg"
import GOTasset from "./img/operations-GOT.png"

export default class Operations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  componentDidMount() {
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.setState({
            isVisible: true,
          })
        }
      })
    }, {})
    observer.observe(this.arrowWrapper)
  }

  render() {
    return (
      <section className={styles.Operations}>
        <div className={styles.p1}>
          <p className={styles.introTitle}>
            Smooth sailing requires shipshape{" "}
          </p>
          <h1 className={styles.title}>OPERATIONS</h1>
          <p className={styles.par}>
            Creating triple-digit SEO pages in single-digit months and spanning
            hyper-specialized topics calls for a well-oiled editorial team that
            scales.
          </p>
        </div>

        <div className={styles.p2}>
          <div>
            <img src={asset1} className={styles.p2img} />
          </div>
          <div className={styles.p2Copy}>
            <h1 className={styles.p2Title}>100+</h1>
            <p className={styles.p2Par}>SMEs interviewed</p>
          </div>
        </div>

        <div>
          <div className={styles.p3}>
            <h1 className={styles.p3Title}>More than just desk research,</h1>
            <p className={styles.p3copy}>
              you need a unique point of view that sets your pages apart. So you
              build a database of subject matter experts (SMEs)—pro
              photographers, designers, illustrators, and videographers— that
              you can interview to find those supremely golden nuggets.
            </p>
          </div>

          <div className={styles.p4}>
            <div
              className={styles.arrow1}
              ref={node => (this.arrowWrapper = node)}
              // style={{ maxHeight: this.state.height + 'px' }}
            >
              <div className={styles.mobile}>
                <svg
                  width="317px"
                  height="644px"
                  viewBox="0 0 317 644"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g
                      id="Adobe-PCR-Mobile"
                      transform="translate(-2.000000, -5105.000000)"
                      stroke="#000000"
                      strokeWidth="3"
                    >
                      <g
                        id="P5-Operations"
                        transform="translate(0.000000, 3778.000000)"
                      >
                        <g
                          id="Arrow"
                          transform="translate(4.163726, 1328.500000)"
                        >
                          <path
                            className={`${
                              this.state.isVisible ? styles.strokeMob : ""
                            }`}
                            d="M63.6514206,2.34478732e-13 C21.2171402,44.7923263 1.32333064e-15,90.4332519 0,136.922777 C0,206.657065 60.8136425,287.973474 131.545513,344.333919 C202.277384,400.694364 295.037434,436.161198 308.497397,506.586548 C317.470706,553.536782 310.905263,597.726967 288.801069,639.157103"
                            id="Path-12"
                          ></path>
                          <polyline
                            className={`${
                              this.state.isVisible ? styles.strokeArrow : ""
                            }`}
                            id="Path-6"
                            transform="translate(290.683992, 635.178113) rotate(-246.000000) translate(-290.683992, -635.178113) "
                            points="284.37417 624.308824 296.993814 635.253282 284.37417 646.047403"
                          ></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className={styles.tablet}>
                <svg
                  width="257px"
                  height="655px"
                  viewBox="0 0 257 655"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g
                      id="Adobe-PCR-Tablet"
                      transform="translate(-119.000000, -5187.000000)"
                      stroke="#000000"
                      strokeWidth="3"
                    >
                      <g id="P5" transform="translate(0.000000, 3823.000000)">
                        <g
                          id="Group-3"
                          transform="translate(121.000000, 1366.000000)"
                        >
                          <path
                            className={`${
                              this.state.isVisible ? styles.stroke : ""
                            }`}
                            d="M170.103545,651 C193.977326,535.884964 184.92536,456.335616 142.947649,412.351955 C79.9810819,346.376463 -4.49719765e-13,379.265625 -2.27263954e-13,275 C-2.66453526e-15,170.734375 98.0611059,170.734375 163.186803,148.867187 C228.3125,127 253,106.68854 253,-9.09494702e-13"
                            id="Path-3"
                          ></path>
                          <polyline
                            className={`${
                              this.state.isVisible ? styles.strokeArrow : ""
                            }`}
                            id="Path-4-Copy"
                            transform="translate(173.290348, 643.000000) rotate(-180.000000) translate(-173.290348, -643.000000) "
                            points="163.186803 646.514618 176.477151 635 183.393893 651"
                          ></polyline>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              {/* <img src={arrow1desc} className={styles.desc} alt="" /> */}

              <div className={styles.desc}>
                <svg
                  width="658px"
                  height="505px"
                  viewBox="0 0 658 505"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Adobe-PCR-Desktop_R2"
                      transform="translate(-101.000000, -6101.000000)"
                    >
                      <g id="P5" transform="translate(0.000000, 4687.000000)">
                        <g
                          id="Arrow"
                          transform="translate(97.396455, 1416.433396)"
                        >
                          <path
                            // style={{ display: 'none' }}
                            className={`${
                              this.state.isVisible ? styles.stroke : ""
                            }`}
                            d="M15.1035448,497.783383 C37.9014525,416.759938 83.0681192,376.248215 150.603545,376.248215 C300.586981,376.248215 324.5,521.283969 472.107024,497.783383 C619.714048,474.282798 674.296475,350.593847 657,246.546447 C639.703525,142.499046 544.558298,25.218131 337.594622,8.52651283e-14"
                            id="Path-3"
                            stroke="#000000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <polyline
                            className={`${
                              this.state.isVisible ? styles.strokeArrow : ""
                            }`}
                            id="Path-6"
                            stroke="#000000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="translate(16.965693, 490.550073) rotate(-255.000000) translate(-16.965693, -490.550073) "
                            points="8.5863841 476.115889 25.3450026 490.649895 8.5863841 504.984257"
                          ></polyline>
                          <path d="" id="Path-2" stroke="#979797"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div className={styles.p4text}>
              <h1 className={styles.p4Title}>5200+</h1>
              <p className={styles.p4copy}>
                minutes of expert advice collected*
              </p>
            </div>
            <div className={styles.p4img}>
              <img src={asset2} />
            </div>
          </div>
        </div>

        <div className={styles.fpo}>
          <img src={GOTasset} className={styles.fpoImg} />
          <br />
          <p className={styles.fpocopy}>
            *You can watch the entire <i>Game of Thrones</i> series in less time
            than that.
          </p>
        </div>

        <div className={styles.block}>
          <div className={styles.blockCopy}>
            To keep to the newsroom-like schedule, a workflow needs to be built,
            managed, and fine-tuned. Writers need enough time to conduct
            interviews and write the articles; designers need enough time to
            make the pages beautiful. <br /> <br />
            <i>And don’t forget time for edits and revisions!</i>
          </div>
        </div>
      </section>
    )
  }
}
