import React, { Component } from "react"

import styles from "./styles.module.less"

//imgs
import oneMob from "./img/1-1.jpg"
import twoMob from "./img/2-2.jpg"

import oneDesc from "./img/1-1-1.jpg"
import twoDesc from "./img/2-2-2.jpg"

export default class Agrade extends Component {
  render() {
    return (
      <>
        <div className={styles.Agrade}>
          <div className={styles.intro}>
            <h2>
              <span className={styles.top}>
                Each page was
                <br />
              </span>
              <span className={styles.middle}>
                A-grade
                <br />
              </span>
              <span className={styles.bottom}>(or better) on Clearscope</span>
            </h2>
          </div>
          <div></div>

          <div className={styles.wrapper}>
            <div className={styles.imgsTop}>
              <img src={oneMob} alt="" />
              <img src={oneDesc} alt="" />
            </div>
            <div className={styles.mid}>
              <h2 className={styles.top}>
                First-page rankings
                <br /> grew
                <br />
              </h2>
              <h2 className={styles.middle}>
                26
                <br />
              </h2>
              <h2 className={styles.percent}>%</h2>
              <h2 className={styles.bottom}>
                quarter
                <br />
                over quarter
              </h2>
            </div>
            <div className={styles.imgsBtm}>
              <img src={twoMob} alt="" />
              <img src={twoDesc} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.AgradeAnimation}>
          <img className={styles.mobileGif} src="/images/A-Grade.gif" alt="" />
          <video
            className={styles.desktopVid}
            src="/images/A-Grade.mp4"
            poster="/images/A-Grade-poster.jpg"
            type="video/mp4"
            autoPlay
            muted
            loop
            playsInline
          ></video>
        </div>
      </>
    )
  }
}
